<template>
  <div>
    <v-layout justify-center wrap>
      <v-flex sm12 md12>
        <v-layout wrap>
          <v-flex
            xs12
            style="color: #000; font-size: 20px; font-family: poppinsbold"
            pt-4
            pb-5
            >Quote Details
          </v-flex>
          <v-layout justify-center wrap>
            <v-flex xs4>
              <v-layout justify-center wrap v-if="quote.name">
                <v-flex xs12>
                  <span class="title1">Name</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ quote.name }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout justify-center wrap v-if="quote.email">
                <v-flex xs12>
                  <span class="title1">Email</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ quote.email }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs4>
              <v-layout justify-center wrap v-if="quote.phone">
                <v-flex xs12>
                  <span class="title1">Phone</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ quote.phone }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 py-5>
              <v-layout justify-center wrap v-if="quote.about">
                <v-flex xs12>
                  <span class="title1">About</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ quote.about }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout justify-start wrap>
          <v-flex xs12 pb-5>
            <span class="title1"> Categories </span>
          </v-flex>
          <v-flex v-for="(list, i) in quote.categories" :key="i">
            <span class="text">♦ {{ list.name }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      quote: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/quote/get",
        headers: {
          "token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.quote,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.quote = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>